import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import Nft from 'svgs/expertises/nfts.svg';
import BoardForPainting from 'svgs/nft/board-for-painting.svg';
import { formatFaqData } from 'utilities/contentful';

import { HUB_URLS } from '../constants';
import { getBlogPagePosts, resolveBlogPageContent } from '../utilities';

const content = resolveBlogPageContent({
    page: 'blockchain',
    paragraphsCounters: { firstRow: 1, secondRow: 1 },
    quoteAuthor: {
        name: 'marcin-sadowski',
        position: 'cto-blockchain-leader',
    },
});

export const useBlogBlockchain = (blogPosts, faqResult) => {
    const { industryLeadersPosts, posts } = getBlogPagePosts(
        blogPosts,
        'industry-leaders',
    );

    const subInfoLinksData = [
        {
            key: 'link',
            url: 'blog/proptech/web3-blockchain-real-estate-companies',
            component: 'gatsby-link',
        },
    ];

    const firstRowLinksData = [
        {
            key: 'flowLink',
            url: 'https://flow.com/',
            component: 'a',
        },
        {
            key: 'nftsLink',
            url: 'https://themobilereality.com/blog/guide-to-nfts-when-to-mint-own-collection',
            component: 'a',
        },
    ];

    const secondRowLinksData = [
        {
            key: 'cryptoLink',
            url: `${PATHS.CASE_STUDIES}/cryptocurrency-exchange-software`,
            component: 'gatsby-link',
        },
    ];

    const images = useStaticQuery(
        graphql`
            query {
                ctoAvatar: file(relativePath: { eq: "team/cto-avatar.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            placeholder: NONE
                            width: 74
                            formats: [WEBP]
                        )
                    }
                }
            }
        `,
    );

    const svgs = {
        firstRowSvg: Nft,
        secondRowSvg: BoardForPainting,
    };

    const faqData = formatFaqData(faqResult);
    content.quoteAuthor.image =
        images.ctoAvatar.childImageSharp.gatsbyImageData;

    return {
        ...images,
        ...svgs,
        ...content,
        industryLeadersPosts,
        subInfoLinksData,
        firstRowLinksData,
        secondRowLinksData,
        posts,
        hub: HUB_URLS.BLOCKCHAIN,
        faqData,
    };
};
