import React from 'react';
import { SingleBlogGenre } from 'views/single-blog-genre';
import { useBlogBlockchain } from 'views/single-blog-genre/hooks/use-blog-blockchain';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/blog.json';
import blockchainMessages from 'constants/translations/blog/blockchain.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const BlogBlockchainPage = ({ location, data }) => {
    const blogPosts = data.allContentfulBlogPost.edges;
    const faqData = data.allContentfulFaq.nodes[0];
    const pageData = useBlogBlockchain(blogPosts, faqData);

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...blockchainMessages }}
        >
            <SingleBlogGenre {...pageData} />
        </Layout>
    );
};

export default BlogBlockchainPage;

BlogBlockchainPage.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export const Head = () => <SEO tags={TAGS.BLOG_BLOCKCHAIN} />;

export const pageQuery = graphql`
    query EnBlockchainBlogPageQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            name: { in: "blockchain-web3", ne: "nft-reality" }
                        }
                    }
                }
            }
            sort: { date: DESC }
        ) {
            edges {
                node {
                    ...BlogPostFragment
                }
            }
        }
        allContentfulFaq(filter: { slug: { eq: "blog/blockchain" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
